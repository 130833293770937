import styled from 'styled-components';
import { mediaMax } from '../../../ui/common';

export const Section = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

export const Block = styled.div`
  flex-basis: auto;
  ${mediaMax.laptop`
    flex-basis: calc(100% / 3);
  `};
`;

export const Image = styled.div`
  width: 100px;
  padding: 0px 42px;
  margin: 0 auto;
  ${mediaMax.phone`
    width: 72px;
    padding: 0px 12px;
  `}
`;
