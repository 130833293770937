import styled from 'styled-components';
import { colors, media, mediaMax } from '../../../../ui/common';

export const Wrap = styled.div`
  padding: 30px 0;
  ${mediaMax.medium`
    padding: 0;
  `}
`;
export const Card = styled.div`
  display: flex;
  color: ${colors.color05};
  box-sizing: border-box;
  ${(props) => props.centered && 'align-items: center'};
  ${(props) => !props.isRight && 'flex-direction: row-reverse'};
  ${mediaMax.medium`
    flex-wrap: wrap; 
    margin: 10px 0;
    padding: 12px 24px;
    align-items: start;
    flex-direction: initial;
  `};
`;
export const Block = styled.div`
  max-width: 390px;
  margin: 0 58px;
  ${(props) => props.centered && 'padding: 40px 0 28px'};
  ${mediaMax.medium`
    margin: 0px 0px;
    padding: 35px 0 28px;
    box-sizing: border-box;
    margin: 0;
    max-width: none;
  `}
`;
export const Title = styled.div`
  font-weight: 600;
  font-size: 28px;
  margin-bottom: 16px;
  ${mediaMax.medium`
    font-size: 32px;
    margin-bottom: 22px;
  `};
`;
export const Text = styled.div`
  opacity: 0.9;
  font-weight: 300;
  font-size: 14px;
  margin-bottom: 16px;
  color: ${colors.color01};
  line-height: 1.8;
  white-space: pre-wrap;
  ${media.medium`
    margin-bottom: 28px;
  `};
`;
export const ImageWrap = styled.div`
  width: 48%;
  ${mediaMax.medium`
    width: 100%;
  `};
`;
export const ListWrapper = styled.div`
  margin-top: 20px;
  ${mediaMax.medium`
    margin-top: 11px; 
  `};
`;
export const ListTitle = styled(Title)`
  margin-top: 5px;
  font-size: 22px !important;
`;
export const ListText = styled(Text)`
  margin-bottom: 40px;
`;
