import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { colors, mediaMax } from '../../../ui/common';
import Description from './DescriptionRow';

const description = graphql`
  {
    block1: file(relativePath: { eq: "promo/description/1@2.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    block2: file(relativePath: { eq: "promo/description/2@2.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    block3: file(relativePath: { eq: "promo/description/3@2.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    block4: file(relativePath: { eq: "promo/description/4@2.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
  }
`;

const promoStyles = {
  color: colors.color05,
  background: 'white',
};

/* eslint-disable react/prop-types, react/destructuring-assignment, react/jsx-props-no-spreading */
function WrapDesc(props) {
  return (
    <Description {...props} styles={promoStyles}>
      {props.children}
    </Description>
  );
}
/* eslint-enable react/prop-types, react/destructuring-assignment, react/jsx-props-no-spreading */

export const Section = styled.section`
  padding-top: 64px;
  ${mediaMax.tablet`
    padding: 10px 0 0;
  `}
`;

function Descriptions() {
  return (
    <StaticQuery
      query={description}
      render={(data) => (
        <Section>
          <WrapDesc
            isRight
            centered={false}
            image={data.block1.childImageSharp.gatsbyImageData}
            title={<FormattedMessage id="promo.block1.headline" />}
            texts={[
              [
                <FormattedMessage id="promo.block1.part1.head" />,
                <FormattedMessage id="promo.block1.part1.text" />,
              ],
              [
                <FormattedMessage id="promo.block1.part2.head" />,
                <FormattedMessage id="promo.block1.part2.text" />,
              ],
              [
                <FormattedMessage id="promo.block1.part3.head" />,
                <FormattedMessage id="promo.block1.part3.text" />,
              ],
            ]}
          />
          <WrapDesc
            image={data.block2.childImageSharp.gatsbyImageData}
            title={<FormattedMessage id="promo.block2.headline" />}
            text={<FormattedMessage id="promo.block2.text" />}
          />
          <WrapDesc
            isRight
            image={data.block3.childImageSharp.gatsbyImageData}
            title={<FormattedMessage id="promo.block3.headline" />}
            text={<FormattedMessage id="promo.block3.text" />}
          />
          <WrapDesc
            image={data.block4.childImageSharp.gatsbyImageData}
            title={<FormattedMessage id="promo.block4.headline" />}
            text={<FormattedMessage id="promo.block4.text" />}
          />
        </Section>
      )}
    />
  );
}

export default Descriptions;
