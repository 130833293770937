import styled from 'styled-components';
import { media } from '../ui/common';
import { colors } from '../../../ui/common';
import { primary } from '../ui/Button';

export const Section = styled.section`
  width: 100%;
  padding: 45px 0 92px;
  overflow: hidden;
`;
export const Note = styled.div`
  font-size: 16px;
  color: ${colors.color01};
  opacity: 0.8;
`;
export const Rating = styled.div`
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  font-size: 28px;
  color: ${colors.color05};
  padding: 16px 0;
  & > div {
    padding-left: 8px;
  }
`;
export const Top = styled.div`
  text-align: center;
  padding: 30px 0;
  ${media.tablet`
    padding: 40px 0;
  `}
  ${media.laptop`
    padding: 53px 0 38px;
  `}
`;
export const List = styled.div`
  overflow: scroll;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0 0 16px;
`;
export const Block = styled.div`
  padding: 8px 8px;
  ${media.tablet`
    padding: 8px 15px;
  `}
`;
export const Bottom = styled.div`
  text-align: center;
`;
export const Link = styled.a`
  font-size: 14px;
  color: ${colors.color01};
  display: block;
  margin-top: 24px;
`;
export const Button = styled.button.attrs(() => ({
  type: 'button',
}))`
  ${primary};
  height: 70px;
  min-width: 220px;
  margin: 16px 0;
  ${media.tablet`
    margin: 32px 0 16px;
  `}
`;
