import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Section, Wrapper } from './styled';

function HowItWorks() {
  const intl = useIntl();

  return (
    <Section>
      <FormattedMessage id="promo.howitworks" tagName="h1" />
      <Wrapper>
        <iframe
          width="560"
          height="315"
          src="https://www.youtube-nocookie.com/embed/Ftrnsw5kU9c"
          title={intl.formatMessage({ id: 'promo.video.title' })}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </Wrapper>
    </Section>
  );
}

export default HowItWorks;
