import React, { useRef, useState } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import getFluid from '../utils/get-fluid';
import Info from '../components/Products/Info';
import Difference from '../components/Products/Difference';
import Reviews from '../components/Products/Reviews';
import FAQ from '../components/Products/FAQ';
import NavBar from '../components/Navbar';
import PopUpAdd from '../components/Products/PopUpAdd';
import HowItWorks from '../components/Promo/HowItWorks';
import Brands from '../components/Promo/Brands';
import Descriptions from '../components/Promo/Descriptions';
import usePathName from '../hooks/use-path-name';
import InfoMain from '../components/Products/Info/Main';
import pagesInfo from '../domain/pages-info';

const promo = graphql`
  {
    right: file(relativePath: { eq: "product-page/evalight/promo-right.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    left: file(relativePath: { eq: "product-page/evalight/promo-left.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    map1: file(relativePath: { eq: "product-page/evalight/map1.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    map2: file(relativePath: { eq: "product-page/evalight/map2.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    image1: file(
      relativePath: { eq: "product-page/evalight/gallery/image-1.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    image2: file(
      relativePath: { eq: "product-page/evalight/gallery/image-2.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    image3: file(
      relativePath: { eq: "product-page/evalight/gallery/image-3.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    image4: file(
      relativePath: { eq: "product-page/evalight/gallery/image-4.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    image5: file(
      relativePath: { eq: "product-page/evalight/gallery/image-5.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    lifestyle1: file(
      relativePath: { eq: "product-page/evalight/gallery/lifestyle1.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    lifestyle2: file(
      relativePath: { eq: "product-page/evalight/gallery/lifestyle2.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    lifestyle3: file(
      relativePath: { eq: "product-page/evalight/gallery/lifestyle3.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    lifestyle4: file(
      relativePath: { eq: "product-page/evalight/gallery/lifestyle4.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    vpreview: file(
      relativePath: { eq: "product-page/evalight/gallery/video.png" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    evachill: file(relativePath: { eq: "product-page/evachill.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    evasmart: file(relativePath: { eq: "product-page/evasmart.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    evalight: file(relativePath: { eq: "product-page/evalight.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    aircon: file(relativePath: { eq: "product-page/aircon.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    fan: file(relativePath: { eq: "product-page/fan.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    technology: file(relativePath: { eq: "product-page/technology.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    white01: file(
      relativePath: { eq: "product-page/evalight/gallery/white/01.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    white02: file(
      relativePath: { eq: "product-page/evalight/gallery/white/02.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    white03: file(
      relativePath: { eq: "product-page/evalight/gallery/white/03.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    white04: file(
      relativePath: { eq: "product-page/evalight/gallery/white/04.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    white05: file(
      relativePath: { eq: "product-page/evalight/gallery/white/05.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    black01: file(
      relativePath: { eq: "product-page/evalight/gallery/black/01.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    black02: file(
      relativePath: { eq: "product-page/evalight/gallery/black/02.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    black03: file(
      relativePath: { eq: "product-page/evalight/gallery/black/03.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    black04: file(
      relativePath: { eq: "product-page/evalight/gallery/black/04.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    black05: file(
      relativePath: { eq: "product-page/evalight/gallery/black/05.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
  }
`;

const info = pagesInfo.evalight;

export default function PromoEvalightplusIphwr3erznb6iytm() {
  const infoRef = useRef(null);
  const [selected, setData] = useState({});

  const pathName = usePathName();

  return (
    <StaticQuery
      query={promo}
      render={(data) => {
        const fluids = new Map([
          [
            'common',
            ['lifestyle1', 'lifestyle2', 'lifestyle3', 'lifestyle4'].map(
              (key) => getFluid(data, key)
            ),
          ],
          [
            'white',
            ['white01', 'white02', 'white03', 'white04', 'white05'].map((key) =>
              getFluid(data, key)
            ),
          ],
          [
            'black',
            ['black01', 'black02', 'black03', 'black04', 'black05'].map((key) =>
              getFluid(data, key)
            ),
          ],
        ]);

        const youtube = {
          url: 'https://www.youtube.com/embed/MKliAK1Mks8',
          aspectRatio: 16 / 9,
          fluid: getFluid(data, 'image1'),
          title:
            'evaLIGHT - cool device to shape your comfort zone. YouTube video.',
        };

        return (
          <Info
            data={info}
            productName={info.title}
            fluids={fluids}
            youtube={youtube}
            onChange={setData}
          >
            <NavBar short logoTo={pathName} />
            <InfoMain ref={infoRef} discount={50} />
            <Brands />
            <Descriptions />
            <Difference
              info={info}
              images={[
                data.evalight.childImageSharp.gatsbyImageData,
                data.aircon.childImageSharp.gatsbyImageData,
                data.fan.childImageSharp.gatsbyImageData,
              ]}
            />
            <HowItWorks />
            <Reviews data={info} />
            <InfoMain showDesc={false} priceMode="bottom" />
            <FAQ />
            <PopUpAdd data={selected} targetRef={infoRef} />
          </Info>
        );
      }}
    />
  );
}
