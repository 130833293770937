import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Container } from '../../../../ui/common';
import {
  Wrap,
  Card,
  ImageWrap,
  Block,
  Title,
  Text,
  ListWrapper,
  ListTitle,
  ListText,
} from './styled';

const List = ({ value }) =>
  value.map((v) => (
    <ListWrapper>
      <ListTitle>{v[0]}</ListTitle>
      <ListText>{v[1]}</ListText>
    </ListWrapper>
  ));

function Description({ title, text, image, isRight, texts, centered }) {
  return (
    <Wrap>
      <Container>
        <Card isRight={isRight} centered={centered}>
          <ImageWrap>
            <GatsbyImage image={image} alt="preview" />
          </ImageWrap>
          <Block centered={centered}>
            <Title>{title}</Title>
            {texts ? <List value={texts} /> : <Text>{text}</Text>}
          </Block>
        </Card>
      </Container>
    </Wrap>
  );
}

export default Description;

Description.propTypes = {
  title: PropTypes.objectOf(PropTypes.any),
  text: PropTypes.objectOf(PropTypes.any),
  image: PropTypes.objectOf(PropTypes.any),
  texts: PropTypes.arrayOf(PropTypes.any),
  isRight: PropTypes.bool,
  centered: PropTypes.bool,
};
Description.defaultProps = {
  title: '',
  text: '',
  image: '',
  texts: null,
  isRight: false,
  centered: true,
};
