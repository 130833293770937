import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Image, Section, Block } from './styled';
import Container from '../../Products/ui/Container';
import items from './data';

const brands = graphql`
  {
    img1: file(relativePath: { eq: "promo/brands/01@2.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    img2: file(relativePath: { eq: "promo/brands/02@2.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    img3: file(relativePath: { eq: "promo/brands/03@2.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    img4: file(relativePath: { eq: "promo/brands/04@2.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    img5: file(relativePath: { eq: "promo/brands/05@2.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    img6: file(relativePath: { eq: "promo/brands/06@2.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
  }
`;

function Brands() {
  return (
    <Container>
      <Section>
        <StaticQuery
          query={brands}
          render={(data) =>
            items.map((value) => (
              <Block>
                <Image key={value}>
                  <GatsbyImage
                    image={data[value].childImageSharp.gatsbyImageData}
                  />
                </Image>
              </Block>
            ))
          }
        />
      </Section>
    </Container>
  );
}

export default Brands;
