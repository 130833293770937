import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Section } from './styled';
import useReviewsSnippet from '../../../hooks/use-reviews-snippet';
import { Container } from '../ui/common';

/* eslint-disable no-unused-vars */
function Reviews({ data }) {
  const locale = useSelector((state) => state.locale.picked);
  useReviewsSnippet(data);

  if (locale === 'ru') return null;

  return (
    <Section>
      <Container>
        <div id="reviewsWidget" />
      </Container>
    </Section>
  );
}
/* eslint-enabled no-unused-vars */

Reviews.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Reviews;
