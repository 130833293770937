import { useEffect } from 'react';
import windowLoad from '../utils/window-load';

const useReviewsSnippet = (data) => {
  /* eslint-disable no-new */
  /* eslint-disable spaced-comment */
  useEffect(() => {
    windowLoad('ReviewsWidget', () => {
      new window.ReviewsWidget('#reviewsWidget', {
        store: 'www.evapolar.com',
        widget: 'polaris',
        options: {
          types: 'product_review,questions',
          lang: 'en',
          layout: '',
          per_page: 15,
          store_review: {
            hide_if_no_results: false,
          },
          third_party_review: {
            hide_if_no_results: false,
          },
          product_review: {
            sku: data.productId.toString(),
            hide_if_no_results: false,
          },
          questions: {
            hide_if_no_results: false,
            enable_ask_question: true,
            show_dates: true,
            grouping: data.productId.toString(),
          },
          header: {
            enable_summary: true,
            enable_ratings: true,
            enable_attributes: true,
            enable_image_gallery: true,
            enable_percent_recommended: false,
            enable_write_review: true,
            enable_ask_question: true,
            enable_sub_header: true,
            rating_decimal_places: 2,
            use_write_review_button: false,
          },
          filtering: {
            enable: true,
            enable_text_search: true,
            enable_sorting: true,
            enable_product_filter: false,
            enable_media_filter: true,
            enable_overall_rating_filter: true,
            enable_language_filter: false,
            enable_language_filter_language_change: false,
            enable_ratings_filters: true,
            enable_attributes_filters: true,
          },
          reviews: {
            enable_avatar: true,
            enable_reviewer_name: true,
            enable_reviewer_address: true,
            reviewer_address_format: 'city, country',
            enable_verified_badge: true,
            enable_subscriber_badge: true,
            review_content_filter: 'undefined',
            enable_reviewer_recommends: true,
            enable_attributes: true,
            enable_product_name: true,
            enable_review_title: undefined,
            enable_replies: true,
            enable_images: true,
            enable_ratings: true,
            enable_share: true,
            enable_helpful_vote: true,
            enable_helpful_display: true,
            enable_report: true,
            enable_date: true,
            enable_third_party_source: true,
          },
        },
        translations: {
          'Verified Customer': 'Verified Customer',
        },
        styles: {
          '--base-font-size': '16px',
          '--common-button-font-family': 'inherit',
          '--common-button-font-size': '16px',
          '--common-button-font-weight': '500',
          '--common-button-letter-spacing': '0',
          '--common-button-text-transform': 'none',
          '--common-button-vertical-padding': '10px',
          '--common-button-horizontal-padding': '20px',
          '--common-button-border-width': '2px',
          '--common-button-border-radius': '0px',

          '--primary-button-bg-color': '#0E1311',
          '--primary-button-border-color': '#0E1311',
          '--primary-button-text-color': '#ffffff',

          '--secondary-button-bg-color': 'transparent',
          '--secondary-button-border-color': '#0E1311',
          '--secondary-button-text-color': '#0E1311',

          '--common-star-color': '#0E1311',
          '--common-star-disabled-color': 'rgba(0,0,0,0.25)',
          '--medium-star-size': '22px',
          '--small-star-size': '19px',

          '--heading-text-color': '#0E1311',
          '--heading-text-font-weight': '600',
          '--heading-text-font-family': 'inherit',
          '--heading-text-line-height': '1.4',
          '--heading-text-letter-spacing': '0',
          '--heading-text-transform': 'none',

          '--body-text-color': '#0E1311',
          '--body-text-font-weight': '400',
          '--body-text-font-family': 'inherit',
          '--body-text-line-height': '1.4',
          '--body-text-letter-spacing': '0',
          '--body-text-transform': 'none',

          '--inputfield-text-font-family': 'inherit',
          '--input-text-font-size': '14px',
          '--inputfield-text-font-weight': '400',
          '--inputfield-text-color': '#0E1311',
          '--inputfield-border-color': 'rgba(0,0,0,0.2)',
          '--inputfield-background-color': 'transparent',
          '--inputfield-border-width': '1px',
          '--inputfield-border-radius': '0px',

          '--common-border-color': 'rgba(0,0,0,0.15)',
          '--common-border-width': '1px',
          '--common-sidebar-width': '190px',

          '--slider-indicator-bg-color': 'rgba(0,0,0,0.1)',
          '--slider-indicator-button-color': '#0E1311',
          '--slider-indicator-width': '190px',

          '--badge-icon-color': '#0E1311',
          '--badge-icon-font-size': 'inherit',
          '--badge-text-color': '#0E1311',
          '--badge-text-font-size': 'inherit',
          '--badge-text-letter-spacing': 'inherit',
          '--badge-text-transform': 'inherit',

          '--author-font-size': 'inherit',
          '--author-text-transform': 'none',

          '--avatar-thumbnail-size': '60px',
          '--avatar-thumbnail-border-radius': '100px',
          '--avatar-thumbnail-text-color': '#0E1311',
          '--avatar-thumbnail-bg-color': 'rgba(0,0,0,0.1)',

          '--photo-video-thumbnail-size': '80px',
          '--photo-video-thumbnail-border-radius': '0px',

          '--mediaslider-scroll-button-icon-color': '#0E1311',
          '--mediaslider-scroll-button-bg-color': 'rgba(255, 255, 255, 0.85)',
          '--mediaslider-overlay-text-color': '#ffffff',
          '--mediaslider-overlay-bg-color': 'rgba(0, 0, 0, 0.8))',
          '--mediaslider-item-size': '110px',

          '--pagination-tab-text-color': '#0E1311',
          '--pagination-tab-text-transform': 'none',
          '--pagination-tab-text-letter-spacing': '0',
          '--pagination-tab-text-font-size': '16px',
          '--pagination-tab-text-font-weight': '600',

          '--pagination-tab-active-text-color': '#0E1311',
          '--pagination-tab-active-text-font-weight': '600',
          '--pagination-tab-active-border-color': '#0E1311',
          '--pagination-tab-border-width': '3px',
        },
      });
    });
  }, []);
  /* eslint-enable no-new */
  /* eslint-enable spaced-comment */
};

export default useReviewsSnippet;
