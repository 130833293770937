import styled from 'styled-components';
import { colors, mediaMax } from '../../../ui/common';

export const Section = styled.section`
  padding: 50px 0 30px;
  h1 {
    font-size: 38px;
    margin: 0 0 60px;
    color: ${colors.color05};
    line-height: 1.5;
    text-align: center;
    font-weight: 600;
  }
  ${mediaMax.laptop`
    padding: 50px 0 70px;
    h1 {
      margin-bottom: 45px;
    }
  `}
  ${mediaMax.phone`
    padding: 40px 0 50px;
    h1 {
      margin-bottom: 32px;
    }
  `}
`;

export const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  padding-bottom: 56.2%;
  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border: 0;
  }
`;
